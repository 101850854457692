//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//





import UMUM from "../../library/umum";
import DATA_MASTER from "../../library/dataMaster";


export default {
  props: ["biodata_id"],
  data() {
    
    return {
      // xbiodata_id : this.biodata_id,
      list_riwayat: [],
      data: {
          riwayat_jabatan_struktural_id : '',
          jabatan : '',
          nama_jabatan : '',
          unit_kerja : '',
          esselon_id : '',
          esselon : '',
          tmt_jabatan : '',
          penanda_tangan_sk : '',
          no_sk : '',
          tgl_sk : '',
          biodata_id : this.biodata_id,
          file : null,
          userId : 0,
          createdAt : '',
          biodata_id : '',

      },
      mdl_detil_riwayat: false,
      mdl_detil_riwayat_add: false,
      mdl_detil_riwayat_add_lampiran: false,
      mdl_detil_riwayat_edit: false,
      mdl_detil_riwayat_hapus: false,
      file_old :'',

      UMUM: UMUM,
      DATA_MASTER: DATA_MASTER,

      btn_add: false,
    };
  },

  methods: {
    getRiwayat: function(){
       fetch(this.$store.state.url.URL_simpeg_riwayat_jabatan_struktural+'view', {
          method : 'POST',
          headers : {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body : JSON.stringify({
            biodata_id : this.data.biodata_id,
          })
      }).then((res) => res.json()).then((res_data) => {
        this.list_riwayat = res_data;
        console.log(res_data);
      })
    },

    addData : function(number) {
      var formData = new FormData();

      formData.append('riwayat_jabatan_struktural_id', this.data.riwayat_jabatan_struktural_id);
      formData.append('jabatan', this.data.jabatan);
      formData.append('unit_kerja', this.data.unit_kerja);
      formData.append('esselon_id', this.data.esselon_id);
      formData.append('tmt_jabatan', this.data.tmt_jabatan);
      formData.append('penanda_tangan_sk', this.data.penanda_tangan_sk);
      formData.append('no_sk', this.data.no_sk);
      formData.append('tgl_sk', this.data.tgl_sk);
      formData.append('biodata_id', this.data.biodata_id);
      formData.append('file', this.data.file);

      fetch(this.$store.state.url.URL_simpeg_riwayat_jabatan_struktural+'createData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then(res => res.json()).then((data_urusan) => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getRiwayat();
      });
     
    },


    editData : function(){

      var formData = new FormData();
      formData.append('riwayat_jabatan_struktural_id', this.data.riwayat_jabatan_struktural_id);
      formData.append('jabatan', this.data.jabatan);
      formData.append('unit_kerja', this.data.unit_kerja);
      formData.append('esselon_id', this.data.esselon_id);
      formData.append('tmt_jabatan', this.data.tmt_jabatan);
      formData.append('penanda_tangan_sk', this.data.penanda_tangan_sk);
      formData.append('no_sk', this.data.no_sk);
      formData.append('tgl_sk', this.data.tgl_sk);
      formData.append('file', this.data.file);
      formData.append('file_old', this.file_old);

      fetch(this.$store.state.url.URL_simpeg_riwayat_jabatan_struktural+'updateData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then((res_data) => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getRiwayat();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_simpeg_riwayat_jabatan_struktural + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.data.riwayat_jabatan_struktural_id, file : this.file_old})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getRiwayat();
      });

    },


    selectRiwayat: function(data){

          this.data.riwayat_jabatan_struktural_id = data.riwayat_jabatan_struktural_id;
          this.data.jabatan = data.jabatan;
          this.data.nama_jabatan = data.nama_jabatan;
          this.data.unit_kerja = data.unit_kerja;
          this.data.esselon_id = data.esselon_id;
          this.data.esselon = data.esselon;
          this.data.tmt_jabatan = data.tmt_jabatan;
          this.data.penanda_tangan_sk = data.penanda_tangan_sk;
          this.data.no_sk = data.no_sk;
          this.data.tgl_sk = data.tgl_sk;
          this.data.biodata_id = data.biodata_id;
          this.data.createdAt = data.createdAt;

          this.file_old = data.file;
    },

    alertku: function(message, positive, icon) {
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position: "top",
          timeout: 500
        });
    },
    Notify: function(message, positive, icon) {
      this.$q.notify({
        message: message,
        color: positive,
        icon: icon,
        position: "top",
        timeout: 500
      });
    },

  },


  mounted () {
    this.getRiwayat()
    DATA_MASTER.getGolongan();
    DATA_MASTER.getEsselon();
  },



};
