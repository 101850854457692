//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//





import UMUM from "../../library/umum";
import DATA_MASTER from "../../library/dataMaster";


export default {
  props: ["biodata_id"],
  data() {
    
    return {
      // xbiodata_id : this.biodata_id,
      list_riwayat: [],
      list_pendidikan :[],
      pendidikan : {
        pendidikan_formal_id : '',
        strata_ijazah_id : '',
        jurusan : '',
        nm_sekolah : '',
        thn_masuk : 0,
        thn_lulus : 0,
        tpt_belajar : '',
        lokasi_pendidikan : '',
        no_ijazah : '',
        biodata_id : this.biodata_id,
        file : null,
        userId : '',
        createdAt : '',
      },
      mdl_detil_riwayat: false,
      mdl_detil_riwayat_add: false,
      mdl_detil_riwayat_add_lampiran: false,
      mdl_detil_riwayat_edit: false,
      mdl_detil_riwayat_hapus: false,
      file_old :'',

      UMUM: UMUM,
      DATA_MASTER: DATA_MASTER,

      btn_add: false,
    };
  },

  methods: {
    getRiwayat: function(){
        fetch(this.$store.state.url.URL_simpeg_pendidikan_formal+'view', {
            method : 'POST',
            headers : {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body : JSON.stringify({
              biodata_id : this.pendidikan.biodata_id,
            })
        }).then((res) => res.json()).then((res_data) => {
          this.list_pendidikan = res_data;
          console.log(res_data);
        })
    },

    addData : function(number) {
      var formData = new FormData();

      var formData = new FormData();
      formData.append('strata_ijazah_id', this.pendidikan.strata_ijazah_id);
      formData.append('jurusan', this.pendidikan.jurusan);
      formData.append('nm_sekolah', this.pendidikan.nm_sekolah);
      formData.append('thn_masuk', this.pendidikan.thn_masuk);
      formData.append('thn_lulus', this.pendidikan.thn_lulus);
      formData.append('tpt_belajar', this.pendidikan.tpt_belajar);
      formData.append('lokasi_pendidikan', this.pendidikan.lokasi_pendidikan);
      formData.append('no_ijazah', this.pendidikan.no_ijazah);
      formData.append('biodata_id', this.pendidikan.biodata_id);
      formData.append('file', this.pendidikan.file);

      fetch(this.$store.state.url.URL_simpeg_pendidikan_formal+'createData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then(res => res.json()).then((data_urusan) => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getRiwayat();
      });
     
    },

    editData : function(){

      var formData = new FormData();
      formData.append('pendidikan_formal_id', this.pendidikan.pendidikan_formal_id);
      formData.append('strata_ijazah_id', this.pendidikan.strata_ijazah_id);
      formData.append('jurusan', this.pendidikan.jurusan);
      formData.append('nm_sekolah', this.pendidikan.nm_sekolah);
      formData.append('thn_masuk', this.pendidikan.thn_masuk);
      formData.append('thn_lulus', this.pendidikan.thn_lulus);
      formData.append('tpt_belajar', this.pendidikan.tpt_belajar);
      formData.append('lokasi_pendidikan', this.pendidikan.lokasi_pendidikan);
      formData.append('no_ijazah', this.pendidikan.no_ijazah);
      formData.append('biodata_id', this.pendidikan.biodata_id);
      formData.append('file', this.pendidikan.file);
      formData.append('file_old', this.file_old);

      fetch(this.$store.state.url.URL_simpeg_pendidikan_formal+'updateData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then((res_data) => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getRiwayat();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_simpeg_pendidikan_formal + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.pendidikan.pendidikan_formal_id, file : this.file_old})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getRiwayat();
      });

    },

    selectRiwayat: function(data){

        this.pendidikan.pendidikan_formal_id = data.pendidikan_formal_id;

        this.pendidikan.strata_ijazah_id = data.strata_ijazah_id;
        this.pendidikan.jurusan = data.jurusan;
        this.pendidikan.nm_sekolah = data.nm_sekolah;
        this.pendidikan.thn_masuk = data.thn_masuk;
        this.pendidikan.thn_lulus = data.thn_lulus;
        this.pendidikan.tpt_belajar = data.tpt_belajar;
        this.pendidikan.lokasi_pendidikan = data.lokasi_pendidikan;
        this.pendidikan.no_ijazah = data.no_ijazah;
        this.pendidikan.createdAt = data.createdAt;
        this.file_old = data.file;



    },
    alertku: function(message, positive, icon) {
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position: "top",
          timeout: 500
        });
    },

    Notify: function(message, positive, icon) {
      this.$q.notify({
        message: message,
        color: positive,
        icon: icon,
        position: "top",
        timeout: 500
      });
    },

  },


  mounted () {
    this.getRiwayat()
    DATA_MASTER.getGolongan();
    DATA_MASTER.getJenisDiklat();
  },



};
