import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './quasar'

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);



import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC-CK-C9E_OOkeMe9IkN-Jcj2Np0n3p_ho',
    v: '3.26',
    libraries: 'places, drawing, visualization',  // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  autobindAllEvents: true,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)

import Print from 'vue-print-nb'
Vue.use(Print);

// import VueHtml2Canvas from 'vue-html2canvas';
// Vue.use(VueHtml2Canvas);


import sideBar from './components/sideBar.vue'
Vue.component('SideBar', sideBar);

import topBar from './components/topBar.vue'
Vue.component('topBar', topBar);

import ProgressLoading from './components/ProgressLoading.vue'
Vue.component('ProgressLoading', ProgressLoading);


import profilDetile from './components/profilDetile.vue'
Vue.component('profilDetile', profilDetile);


import contentTable from './views/home/contentTable.vue'
Vue.component('contentTable', contentTable);

import contentChart from './views/home/chart.vue'
Vue.component('contentChart', contentChart);


import contentWidget from './views/home/contentWidget.vue'
Vue.component('contentWidget', contentWidget);

import contentDaftarPensiun from './views/home/contentDaftarPensiun.vue'
Vue.component('contentDaftarPensiun', contentDaftarPensiun);


import indikatorKualitas from './components/indikatorKualitas.vue'
Vue.component('indikatorKualitas', indikatorKualitas);

import indikatorPersentase from './components/indikatorPersentase.vue'
Vue.component('indikatorPersentase', indikatorPersentase);


// ===================== KOMPONEN RIWAYAT ==========================

import compKarpeg from './components/KumpulanRiwayat/compKarpeg.vue'
Vue.component('compKarpeg', compKarpeg);
import compSKP from './components/KumpulanRiwayat/compSKP.vue'
Vue.component('compSKP', compSKP);
import compPengangkatan from './components/KumpulanRiwayat/compPengangkatan.vue'
Vue.component('compPengangkatan', compPengangkatan);
import compKepangkatan from './components/KumpulanRiwayat/compKepangkatan.vue'
Vue.component('compKepangkatan', compKepangkatan);
import compStruktural from './components/KumpulanRiwayat/compStruktural.vue'
Vue.component('compStruktural', compStruktural);
import compFungsional from './components/KumpulanRiwayat/compFungsional.vue'
Vue.component('compFungsional', compFungsional);
import compSuamiIstri from './components/KumpulanRiwayat/compSuamiIstri.vue'
Vue.component('compSuamiIstri', compSuamiIstri);
import compAnak from './components/KumpulanRiwayat/compAnak.vue'
Vue.component('compAnak', compAnak);
import compPendFormal from './components/KumpulanRiwayat/compPendFormal.vue'
Vue.component('compPendFormal', compPendFormal);
import compPendStruktural from './components/KumpulanRiwayat/compPendStruktural.vue'
Vue.component('compPendStruktural', compPendStruktural);
import compPendFungsional from './components/KumpulanRiwayat/compPendFungsional.vue'
Vue.component('compPendFungsional', compPendFungsional);

// ===================== KOMPONEN RIWAYAT ==========================


import pdfViewKu from './components/pdfView.vue'
Vue.component('pdfViewKu', pdfViewKu);




const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    './css/kiken.css',
    './css/bg.css',
    './css/huruf.css',
    './css/modal.css',
    './css/input.css',
    './css/huruf.css',
    // './css/print.css',
    'https://cdn.jsdelivr.net/npm/quasar@2.10.1/dist/quasar.prod.css',
    
  ]
}

import VueHtmlToPaper from 'vue-html-to-paper';
Vue.use(VueHtmlToPaper, options);



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
