import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);





function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}

  const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () => import('../views/worksheet/worksheet_v2.vue'),
    beforeEnter: isLoggedIn,
  },


  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    beforeEnter: isLoggedIn,
    },
  
  {
    path: '/periode',
    name: 'periode',
    component: () => import('../views/periode/periode.vue'),
    beforeEnter: isLoggedIn,
  },



  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile/profile.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/manual_ik_unit',
    name: 'manual_ik_unit',
    component: () => import('../views/skpJPT/manual_ik_unit.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/pk_unit_sasaran',
    name: 'pk_unit_sasaran',
    component: () => import('../views/skpJPT/pk_unit_sasaran.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/rencana_aksi_strategis',
    name: 'rencana_aksi_strategis',
    component: () => import('../views/skpJPT/rencana_aksi_strategis.vue'),
    beforeEnter: isLoggedIn,
  },
  

  {
    path: '/mph_jpt',
    name: 'mph_jpt',
    component: () => import('../views/pembagianPeran/mph_jpt.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/mph_umum',
    name: 'mph_umum',
    component: () => import('../views/pembagianPeran/mph_umum.vue'),
    beforeEnter: isLoggedIn,
  },



  {
    path: '/pk_unit_sasaran_umum',
    name: 'pk_unit_sasaran_umum',
    component: () => import('../views/skpUmum/pk_unit_sasaran_umum.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/rencana_aksi_strategis_umum',
    name: 'rencana_aksi_strategis_umum',
    component: () => import('../views/skpUmum/rencana_aksi_strategis_umum.vue'),
    beforeEnter: isLoggedIn,
  },



  {
    path: '/worksheet_harian',
    name: 'worksheet_harian',
    component: () => import('../views/worksheet/worksheet.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/worksheet_harian_v2',
    name: 'worksheet_harian_v2',
    component: () => import('../views/worksheet/worksheet_v2.vue'),
    beforeEnter: isLoggedIn,
    },
  {
    path: '/worksheetVerifikasi',
    name: 'worksheetVerifikasi',
    component: () => import('../views/worksheet/worksheetVerifikasi'),
    beforeEnter: isLoggedIn,
    },
  

    {
      path: '/lap_harian',
      name: 'lap_harian',
      component: () => import('../views/laporan/lap_harian.vue'),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/lap_skp_jpt',
      name: 'lap_skp_jpt',
      component: () => import('../views/laporan/lap_skp_jpt'),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/lap_tpp_personal',
      name: 'lap_tpp_personal',
      component: () => import('../views/laporan/lap_tpp_personal'),
      beforeEnter: isLoggedIn,
    },









  // ============================== DATA MASTER ==================================

 

  {
    path: '/master_jenis_indikator',
    name: 'master_jenis_indikator',
    component: () => import('../views/dataMaster/master_jenis_indikator.vue'),
    beforeEnter: isLoggedIn,
  },

 

  // ============================== DATA MASTER ==================================

]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
