var DataStore = require('../store');
const Swal = require('sweetalert2')
var store = DataStore.default


const tglConvert = (tgl) => {
    var date = new Date(tgl);
    var getBulan = date.getMonth() + 1; var bulan = '';
    if (getBulan == '1') {bulan = 'Jan'} 
    else if(getBulan == '2') {bulan = 'Feb'}
    else if(getBulan == '3') {bulan = 'Mar'}
    else if(getBulan == '4') {bulan = 'Apr'}
    else if(getBulan == '5') {bulan = 'Mei'}
    else if(getBulan == '6') {bulan = 'Jun'}
    else if(getBulan == '7') {bulan = 'Jul'}
    else if(getBulan == '8') {bulan = 'Agt'}
    else if(getBulan == '9') {bulan = 'Sep'}
    else if(getBulan == '10') {bulan = 'Okt'}
    else if(getBulan == '11') {bulan = 'Nov'}
    else if(getBulan == '12') {bulan = 'Des'}

    return date.getDate() + " " + bulan + " " + date.getFullYear();
}

const bulanByNumber = (getBulan) => {
    var bulan = ''
    if (getBulan == '1') {bulan = 'Januari'} 
    else if(getBulan == '2') {bulan = 'Februari'}
    else if(getBulan == '3') {bulan = 'Marer'}
    else if(getBulan == '4') {bulan = 'April'}
    else if(getBulan == '5') {bulan = 'Mei'}
    else if(getBulan == '6') {bulan = 'Juni'}
    else if(getBulan == '7') {bulan = 'Juli'}
    else if(getBulan == '8') {bulan = 'Agustus'}
    else if(getBulan == '9') {bulan = 'September'}
    else if(getBulan == '10') {bulan = 'Oktober'}
    else if(getBulan == '11') {bulan = 'November'}
    else if (getBulan == '12') { bulan = 'Desember' }
    
    return bulan
}

const toDate = (tgl) => {
    console.log(typeof(tgl))
    var date = new Date(tgl);
    console.log(tgl)
    console.log(date.toString())
  
    return date.toString();;
}

const check_gelar_depan = (data) => {
    if (data == undefined || data == null || data == '') {
        return ''
    } else {
        return data+'. '
    }
}
const check_gelar_belakang = (data1) => {
    if (data1 == undefined || data1 == null || data1 == '') {
        return ''
    } else {
        return ', '+data1
    }
}

const namaLengkap = (gelardepan, nama, gelarBelakang) =>{
    return check_gelar_depan(gelardepan)+""+nama+""+check_gelar_belakang(gelarBelakang)
}


// =================== PAGINASI =========================

const btn_prev = (page_first) =>{

}

const btn_next = (page_first, page_last) => {

}

const checkIndikator = (data)=>{
    if (data == 0 || data == null || data == undefined) {
        return 'deep-orange'
    } else {
        return 'primary'
    }
}

const checkIndikator1 = (data)=>{
    if (data == 0 || data == null || data == undefined) {
        return 'deep-orange'
    } else {
        return 'primary'
    }
}

const checkIndikator2 = (data)=>{
    if (data == 0 || data == null || data == undefined) {
        return 'amber-1'
    } else if (data == 1) {
        return 'light-green-2'
    } else if (data == 2) {
        return 'deep-orange-1'
    }
}


const checkIndikator3 = (data)=>{
    if (data >= 1) {
        return 'deep-orange-8'
    } else {
        return 'primary'
    }
}


const confirmx = async (text)=>{
    return new Promise (resolve =>{
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
          }).then((result) => {
            if (result.isConfirmed) {
            //   Swal.fire(
            //     'Deleted!',
            //     'Your file has been deleted.',
            //     'success'
            //   )
              resolve('');
            } else{
                return false;
            }
          })
    })
}




const postListPeriode = (nip) => {
    fetch(store.state.url.URL_kinerja_periode + "list", {
        method : 'POST',
        headers : {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
        },
        body : JSON.stringify({
            nip : nip
        })
    }).then((res) => res.json()).then((res_data) => {
        store.state.list_periode = res_data
        // console.log(res_data)
    })
}


const kualitasKerja = (nilai) => {
    if (nilai>=110) {
        return 'Sangat Baik'
    }

    else if (nilai>=90 && nilai< 110 ) {
        return 'Baik'
    }
    else if (nilai>=70 && nilai< 90 ) {
        return 'Cukup'
    }
        
    else if (nilai>=50 && nilai< 70 ) {
        return 'Kurang'
    }

    else if (nilai >= 0.01 && nilai < 50 ) {
        return 'Sangat Kurang'
    }

    else if (nilai >= 0 && nilai < 0.01) {
        return 'Tidak Ada Laporan'
    }
}



const lasDT = (tahun, bulan) => {
    int_d = new Date(tahun, bulan,1);
    dx = new Date(int_d - 1);
    lastDate = dx.getDate()

    return lastDate
}


const jenisSasaran = (data) => {
    if (data == 0) {
        return 'Kinerja Utama'
    } else {
        return 'Kinerja Tambahan'
    }
}


const konversiKinerja = (data) => {

    var stat = 0

    if (data > 109 && data <= 120) {
        stat = 100
    }
    else if (data > 89 && data <= 109){
        stat = 100
    } 
    else if (data > 69 && data <= 89){
        stat = 75
    }
    else if (data > 49 && data <= 69){
        stat = 50
    }else if (data > 1 && data <= 49){
        stat = 25
    }else {
        stat = 0
    }





    return stat



}


const hitungTPP = (konversi_target, pagu_tpp, tidak_apel, TK, TL_1, TL_2, TL_3, TL_4, PSW_1, PSW_2, PSW_3, PSW_4, pph21 ) => {


    let prod_kerja =(konversi_target/100)*(60/100)*pagu_tpp;

    let j_TK = TK*25;
    let j_TL_1 = TL_1*0.5;
    let j_TL_2 = TL_2*1;
    let j_TL_3 = TL_3*1.25;
    let j_TL_4 = TL_4*1.5;

    let j_PSW_1 = PSW_1*0.5;
    let j_PSW_2 = PSW_2*1;
    let j_PSW_3 = PSW_3*1.25;
    let j_PSW_4 = PSW_4*1.55;

    let total_tidak_apel = tidak_apel * (2/100)
    let nilai_tidak_apel = pagu_tpp*(40/100)*(total_tidak_apel/100)

    let total_TL_PSW = j_TK + j_TL_1 + j_TL_2 + j_TL_3 + j_TL_4 + j_PSW_1 + j_PSW_2 + j_PSW_3 + j_PSW_4
    let nilai_TL_PSW = pagu_tpp * (total_TL_PSW/100)

    let disiplin_kerja  =( (40/100) * pagu_tpp) - (nilai_TL_PSW + nilai_tidak_apel)


    
    
    let besaranTPP = prod_kerja + disiplin_kerja
    
    val = besaranTPP - pph21 - (besaranTPP*(1/100));
    
    console.log(j_TL_1);



    if (val < 0) {
        return 0
    } else {
        
        return val
    }
    








}





module.exports = {
    tglConvert : tglConvert,
    check_gelar_depan : check_gelar_depan,
    check_gelar_belakang : check_gelar_belakang,
    namaLengkap : namaLengkap,
    btn_prev : btn_prev,
    btn_next : btn_next,
    toDate : toDate,
    checkIndikator : checkIndikator,
    checkIndikator1: checkIndikator1,
    checkIndikator2 : checkIndikator2,
    postListPeriode: postListPeriode,
    confirmx: confirmx,
    bulanByNumber: bulanByNumber,
    kualitasKerja: kualitasKerja,
    lasDT: lasDT,
    jenisSasaran: jenisSasaran,
    checkIndikator3 : checkIndikator3,

    konversiKinerja : konversiKinerja,

    hitungTPP : hitungTPP,
}