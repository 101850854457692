//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      alert: false,
      simpan1: false,
      model: "",
      list_data : [],
      data : {}
    };
  },
  methods: {
    getData: function(){
      fetch(this.$store.state.url.URL_simpeg_home+'jml_pegawai', {
          method: "GET",
          headers: {
            "content-type": "application/json",
            authorization : 'kikensbatara '+localStorage.token
          },
      })
      .then(res => res.json())
      .then((res_data) => {
          console.log(res_data)
          this.data = res_data;
          // this.presentase_sekolah();
          this.chartJenisKelamin();
          this.chartPendidikanAhir();
          this.getDataPensiun();
      });
    },

    getDataPensiun: function(){
      fetch(this.$store.state.url.URL_simpeg_home+'jml_pensiun', {
          method: "GET",
          headers: {
            "content-type": "application/json",
            authorization : 'kikensbatara '+localStorage.token
          },
      })
      .then(res => res.json())
      .then((res_data) => {
        this.list_data = res_data
      });
    },


    chartJenisKelamin: function() {



      Highcharts.chart("chartJenisKelamin", {
        chart: {
          type: "pie",
          backgroundColor: "transparent"
        },
        title: {
          text: "<span class='h_title_home' style='color:#016fce'>PRESENTASE BERDASARKAN JENIS KELAMIN</span>"
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Persentase",
            colorByPoint: true,
            data: [
              {
                name: "Pria",
                y: this.data.jml_pria,
                color: "#1e88e5"
              },
              {
                name: "Wanita",
                y: this.data.jml_wanita,
                color: "#7cb342"
              },

            ]
          }
        ]
      });




    },

    chartPendidikanAhir: function() {

      var data = this.data

      const chart = Highcharts.chart("chartPendidikanAhir", {
        chart: {
          backgroundColor: "transparent"
        },
        title: {
          text: "<span class='h_title_home' style='color:#3a90e2'>PRENSENTASE PENDIDIKAN AKHIR (FORMAL)</span>",
        },
        subtitle: {
          text: "Plain"
        },
        xAxis: {
          categories: ["SD", "SMP/Mts", "SMA(Sederajat)", "D1", "D2", "D3",  "S1/D4", "S2", "S3"],
        },
        yAxis: {
            title: false,
            
        },
        series: [
          {
            type: "column",
            // colorByPoint: true,
            data: [data.pendidikan_ahir_sd, data.pendidikan_ahir_sltp, data.pendidikan_ahir_SLTA, data.pendidikan_ahir_d1, data.pendidikan_ahir_d2, data.pendidikan_ahir_d3, data.pendidikan_ahir_s1, data.pendidikan_ahir_s2, data.pendidikan_ahir_s3],
            showInLegend: false,
            
          },
          
        ]
      });




    },








    async button() {
      await setTimeout(() => {
        console.log("ini query pembacaannya");
      }, 2000);
      console.log("telASO");
    }
  },

  mounted() {
    this.getData();
  }
};
