//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["biodata_id"],
  data() {
    
    return {
      // xbiodata_id : this.biodata_id,
      list_riwayat: [],
      data: {
        data_karpeg_id: "",
        no_registrasi: "",
        no_kepegawaian: "",
        biodata_id: this.biodata_id,
        file: null,
        userId: "",
        createdAt: ""
      },
      mdl_detil_riwayat: false,
      mdl_detil_riwayat_add: false,
      mdl_detil_riwayat_add_lampiran: false,
      mdl_detil_riwayat_edit: false,
      mdl_detil_riwayat_hapus: false,
      file_old :'',
    };
  },

  methods: {
    getRiwayat: function(){
        fetch(this.$store.state.url.URL_simpeg_karpeg+'view', {
            method : 'POST',
            headers : {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body : JSON.stringify({
              biodata_id : this.biodata_id,
            })
        }).then((res) => res.json()).then((res_data) => {
          this.list_riwayat = res_data;
          // console.log(res_data);
        })
    },
    addData: function(){

      var formData = new FormData();
      formData.append('data_karpeg_id', this.data.data_karpeg_id);
      formData.append('no_registrasi', this.data.no_registrasi);
      formData.append('no_kepegawaian', this.data.no_kepegawaian);
      formData.append('biodata_id', this.data.biodata_id);
      formData.append('file', this.data.file);

      fetch(this.$store.state.url.URL_simpeg_karpeg+'createData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then(res => res.json()).then((data_urusan) => {
          this.alertku("Berhasil Menambah Data!", "blue");
          this.getRiwayat();
      });
    },

    editData: function(){
        var formData = new FormData();
        formData.append('data_karpeg_id', this.data.data_karpeg_id);
        formData.append('no_registrasi', this.data.no_registrasi);
        formData.append('no_kepegawaian', this.data.no_kepegawaian);
        formData.append('biodata_id', this.data.biodata_id);
        formData.append('file', this.data.file);
        formData.append('file_old', this.file_old);

        fetch(this.$store.state.url.URL_simpeg_karpeg+'updateData', {
            method: "POST",
            headers: {
                authorization : 'kikensbatara '+localStorage.token
            },
            body: formData
        }).then((res_data) => {
            this.alertku("Berhasil Mengubah Data!", "amber");
            this.getRiwayat();
        });
    },

    removeData: function(E) {
      fetch(this.$store.state.url.URL_simpeg_karpeg + "removeData", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({ id: this.data.data_karpeg_id, file: this.file_old })
      }).then(res_data => {
        this.alertku("Berhasil Menghapus Data!", "red");
        this.getRiwayat();
      });
    },


    selectRiwayat: function(data){

            this.data.data_karpeg_id = data.data_karpeg_id;
            this.data.no_registrasi = data.no_registrasi;
            this.data.no_kepegawaian = data.no_kepegawaian;
            this.data.biodata_id = data.biodata_id;
            // this.data.file = data.file;
            this.data.userId = data.userId;
            this.data.createdAt = data.createdAt;
   
            this.file_old = data.file;
    },

    alertku: function(message, positive, icon) {
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position: "top",
          timeout: 500
        });
      }

  },


  mounted () {
    this.getRiwayat()
  },



};
