//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";



export default {

  props: ["pdfFile", 'type'],
  components: {
      VuePdfApp,
  },
}
