import { render, staticRenderFns } from "./compAnak.vue?vue&type=template&id=77903c1c&"
import script from "./compAnak.vue?vue&type=script&lang=js&"
export * from "./compAnak.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCardSection,QBtn,QList,QExpansionItem,QCard,QTooltip,QMarkupTable,QDialog,QIcon,QCardActions,QFile,QInput,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection,QBtn,QList,QExpansionItem,QCard,QTooltip,QMarkupTable,QDialog,QIcon,QCardActions,QFile,QInput})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
