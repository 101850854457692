//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//





import UMUM from "../../library/umum";
import DATA_MASTER from "../../library/dataMaster";


export default {
  props: ["biodata_id"],
  data() {
    
    return {
      // xbiodata_id : this.biodata_id,
      list_riwayat: [],
      list_pendidikan :[],
      pendidikan : {
        diklat_struktural_id : '',
        jns_diklat_id : '',
        angkatan : '',
        penyelenggara : '',
        tgl_mulai : '',
        tgl_selesai : '',
        jumlah_jam : 0,
        predikat : '',
        lokasi_pendidikan : '',
        no_ijazah : '',
        biodata_id : this.biodata_id,
        file : null,
        userId : '',
        createdAt : '',
      },
      mdl_detil_riwayat: false,
      mdl_detil_riwayat_add: false,
      mdl_detil_riwayat_add_lampiran: false,
      mdl_detil_riwayat_edit: false,
      mdl_detil_riwayat_hapus: false,
      file_old :'',

      UMUM: UMUM,
      DATA_MASTER: DATA_MASTER,

      btn_add: false,
    };
  },

  methods: {
    getRiwayat: function(){
        fetch(this.$store.state.url.URL_simpeg_diklat_struktural+'view', {
            method : 'POST',
            headers : {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body : JSON.stringify({
              biodata_id : this.pendidikan.biodata_id,
            })
        }).then((res) => res.json()).then((res_data) => {
          this.list_pendidikan = res_data;
          // console.log(res_data);
        })
    },

    addData : function(number) {
      var formData = new FormData();

      var formData = new FormData();
      formData.append('diklat_struktural_id', this.pendidikan.diklat_struktural_id);
      formData.append('jns_diklat_id', this.pendidikan.jns_diklat_id);
      formData.append('angkatan', this.pendidikan.angkatan);
      formData.append('penyelenggara', this.pendidikan.penyelenggara);
      formData.append('tgl_mulai', this.pendidikan.tgl_mulai);
      formData.append('tgl_selesai', this.pendidikan.tgl_selesai);
      formData.append('jumlah_jam', this.pendidikan.jumlah_jam);
      formData.append('predikat', this.pendidikan.predikat);
      formData.append('lokasi_pendidikan', this.pendidikan.lokasi_pendidikan);
      formData.append('no_ijazah', this.pendidikan.no_ijazah);
      formData.append('biodata_id', this.pendidikan.biodata_id);
      formData.append('file', this.pendidikan.file);

      fetch(this.$store.state.url.URL_simpeg_diklat_struktural+'createData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then(res => res.json()).then((data_urusan) => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getRiwayat();
      });
     
    },


    editData : function(){

      var formData = new FormData();
      formData.append('diklat_struktural_id', this.pendidikan.diklat_struktural_id);
      formData.append('jns_diklat_id', this.pendidikan.jns_diklat_id);
      formData.append('angkatan', this.pendidikan.angkatan);
      formData.append('penyelenggara', this.pendidikan.penyelenggara);
      formData.append('tgl_mulai', this.pendidikan.tgl_mulai);
      formData.append('tgl_selesai', this.pendidikan.tgl_selesai);
      formData.append('jumlah_jam', this.pendidikan.jumlah_jam);
      formData.append('predikat', this.pendidikan.predikat);
      formData.append('lokasi_pendidikan', this.pendidikan.lokasi_pendidikan);
      formData.append('no_ijazah', this.pendidikan.no_ijazah);
      formData.append('biodata_id', this.pendidikan.biodata_id);
      formData.append('file', this.pendidikan.file);
      formData.append('file_old', this.file_old);

      fetch(this.$store.state.url.URL_simpeg_diklat_struktural+'updateData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then((res_data) => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getRiwayat();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_simpeg_diklat_struktural + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.pendidikan.diklat_struktural_id, file : this.file_old})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getRiwayat();
      });

    },

    selectRiwayat: function(data){

          this.pendidikan.diklat_struktural_id = data.diklat_struktural_id;
          this.pendidikan.jns_diklat_id = data.jns_diklat_id;
          this.pendidikan.angkatan = data.angkatan;
          this.pendidikan.penyelenggara = data.penyelenggara;
          this.pendidikan.tgl_mulai = data.tgl_mulai;
          this.pendidikan.tgl_selesai = data.tgl_selesai;
          this.pendidikan.jumlah_jam = data.jumlah_jam;
          this.pendidikan.predikat = data.predikat;
          this.pendidikan.lokasi_pendidikan = data.lokasi_pendidikan;
          this.pendidikan.no_ijazah = data.no_ijazah;


          this.pendidikan.nm_diklat = data.nm_diklat;
          this.pendidikan.tpt_belajar = data.tpt_belajar;
          this.pendidikan.jumlah_jam = data.jumlah_jam;
          this.pendidikan.createdAt = data.createdAt;

          this.file_old = data.file;

    },

    alertku: function(message, positive, icon) {
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position: "top",
          timeout: 500
        });
    },
    Notify: function(message, positive, icon) {
      this.$q.notify({
        message: message,
        color: positive,
        icon: icon,
        position: "top",
        timeout: 500
      });
    },

  },


  mounted () {
    this.getRiwayat()
    DATA_MASTER.getGolongan();
    DATA_MASTER.getJenisDiklat();
  },



};
