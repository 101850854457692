import Vue from 'vue'
import Vuex from 'vuex'

import { Loading,  QSpinnerFacebook,  } from 'quasar'
import { Notify } from 'quasar'

var URL = 'https://server-egov.konaweselatankab.go.id/';
// var URL = 'http://localhost:5095/';


// var URL = 'http://192.168.239.34:5000/';


import Routerku from '../library/routerku'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    coordinat : {
      lat:-4.034694, 
      lng: 122.484263
    },
    btn : {
      add : false,
      edit : false,
      remove : false,
    },
    url : {
      URL_APP: URL,

      URL_kinerja_master_jenis_indikator : URL + 'api/v1/kinerja_master_jenis_indikator/',
      URL_kinerja_master_jenis_sasaran_ku : URL + 'api/v1/kinerja_master_jenis_sasaran_ku/',
      URL_kinerja_master_periode_pelaporan: URL + 'api/v1/kinerja_master_periode_pelaporan/',
      URL_kinerja_master_perspektif : URL + 'api/v1/kinerja_master_perspektif/',

      URL_kinerja_asn : URL + 'api/v1/kinerja_asn/',
      URL_kinerja_pk_unit_sasaran : URL + 'api/v1/kinerja_pk_unit_sasaran/',
      URL_kinerja_pk_unit_sasaran_indikator : URL + 'api/v1/kinerja_pk_unit_sasaran_indikator/',
      URL_kinerja_manual_IK_JPT : URL + 'api/v1/kinerja_manual_IK_JPT/',

      URL_kinerja_ku_ra_strategis : URL + 'api/v1/kinerja_ku_ra_strategis/',
      URL_kinerja_ku_ra_strategis_indikator : URL + 'api/v1/kinerja_ku_ra_strategis_indikator/',

      URL_kinerja_worksheet: URL + 'api/v1/kinerja_worksheet/',
      URL_kinerja_periode : URL + 'api/v1/kinerja_periode/',



      // =================== SIMPEG =====================
      URL_simpeg_home : URL + 'api/v1/simpeg_home/',


      URL_planning_provinsi : URL + 'api/v1/planning_provinsi/',
      URL_planning_kabupaten : URL + 'api/v1/planning_kabupaten/',
      URL_planning_kecamatan : URL + 'api/v1/planning_kecamatan/',
      URL_simpeg_instansi : URL + 'api/v1/simpeg_instansi/',
      URL_simpeg_unit_kerja : URL + 'api/v1/simpeg_unit_kerja/',

      URL_simpeg_jenis_jabatan : URL + 'api/v1/simpeg_jenisJabatan/',
      URL_simpeg_kelas_jabatan : URL + 'api/v1/simpeg_kelasJabatan/',
      URL_simpeg_jabatan : URL + 'api/v1/simpeg_jabatan/',
      
      URL_simpeg_biodata : URL + 'api/v1/simpeg_biodata/',


      checkAuth : URL + 'api/v1/checkAuth/',

      // =================== SIMPEG =====================

    
    },

    listJenisIndikator: [],
    listJenisSasaranKU: [],
    listPeriodePelaporan: [],
    listPerspektif: [],


    listKabKota: [],
    listKec: [],
    listDesKel: [],

    list_instansi: [],
    list_unit_kerja: [],
    list_jenis_jabatan: [],
    list_kelas_jabatan: [],
    list_jabatan: [],
    list_periode : [],


    agamaSelect : [
      {id : '1', nama : 'Islam'},
      {id : '2',nama : 'Katolik'},
      {id : '3',nama : 'Protestan'},
      {id : '4',nama : 'Hindu'},
      {id : '5',nama : 'Buddha'},
      {id : '6',nama : 'Kong Hu Cu'},
    ],

    golonganSelect : [],
    agamaSelect : [],
    jenisKelSelect : [],
    statusKelSelect : [],
    pendidikanAkhirSelect : [],

    esselonSelect: [],

    kepemilikanTaspenSelect : [
      {id : '1',nama : 'Ada'},
      {id : '2',nama : 'Tidak Ada'},
    ],

    levelJabatanSelect : [
      {id : 1, _nama : 'I'},
      {id : 2 ,_nama : 'II'},
      {id : 3, _nama : 'III'},
      {id : 4, _nama : 'IV'},
    ],

    unit_kerja : '',

    page_first: 1,
    page_last: 0,
    cari_value: "",
    cek_load_data : true,


    menu : Routerku.menu,
    tahun : [2021,2022,2023,2024,2025],
    bulan : [
      {id : 1, uraian : 'Januari'},
      {id : 2, uraian : 'Februari'},
      {id : 3, uraian : 'Maret'},
      {id : 4, uraian : 'April'},
      {id : 5, uraian : 'Mei'},
      {id : 6, uraian : 'Juni'},
      {id : 7, uraian : 'Juli'},
      {id : 8, uraian : 'Agustus'},
      {id : 9, uraian : 'September'},
      {id : 10, uraian : 'Oktober'},
      {id : 11, uraian : 'November'},
      {id : 12, uraian : 'Desember'},
    ]
    
  },
  mutations: {

    getStorage(state){
      var get_profile = JSON.parse(localStorage.profile);
      state.unit_kerja = get_profile.profile.unit_kerja; 
    },
    shoWLoading(){
      const spinner = typeof QSpinnerFacebook !== 'undefined'
        ? QSpinnerFacebook // Non-UMD, imported above
        : Quasar.components.QSpinnerFacebook // eslint-disable-line


      Loading.show({
        spinner,
        spinnerColor: 'yellow',
        spinnerSize: 140,
        backgroundColor: 'purple',
      })
    },
    hideLoading(){
      Loading.hide()
    },
    shoWNotify(state, message, color, icon){
      Notify.create({
        message: message,
        color: color,
        position : 'top-right',
        icon:icon
      })
    },
  },
  actions: {
  },
  modules: {
  }
})
