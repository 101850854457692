//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      leftDrawerOpen: true,
      nama : '',
      unit_kerja_nama : ''
    };
  },

  methods: {
    logout : function(){
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        this.$router.push('/login');
    },


    checkLength(data){
      if (data.length <= 0) {
        return false
      } else {
        return true        
      }
    },

    

    checkOtorisasi(){
      console.log(this.$store.state.url.checkAuth)
      fetch(this.$store.state.url.checkAuth, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            }
        })
            .then(res => res.json())
            .then(res_data => {
                // console.log(res_data.message)
                if (res_data.message=='SSILAHKAN LOGIN DULU..!!!') {
                  localStorage.removeItem('token');
                  localStorage.removeItem('profile');
                  this.$router.push('/login');
                  // console.log("harus logout")
                } else {
                  return false
                }
        });
    },


  },


  mounted () {

    this.checkOtorisasi();
    
    var get_profile = JSON.parse(localStorage.profile);
    this.nama = get_profile.profile.nama;
    this.unit_kerja_nama = get_profile.profile.unit_kerja_nama;


  },
  
};
