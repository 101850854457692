//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UMUM from "../../library/umum.js";
export default {
  props: ["list_data"],
  data() {
      return {
          UMUM: UMUM,
      }
  },
};
