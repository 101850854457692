//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//





import UMUM from "../../library/umum";
import DATA_MASTER from "../../library/dataMaster";


export default {
  props: ["biodata_id"],
  data() {
    
    return {
      // xbiodata_id : this.biodata_id,
      list_riwayat: [],
      data: {
        data_anak_id : '',
        nama : '',
        no_akta : '',
        tgl_lahir : '',
        jns_kelamin_id : '',
        strata_ijazah_id : '',
        pekerjaan : '',
        jns_status_anak_id : '',
        biodata_id : this.biodata_id,
        file : null,
        userId : '',
        createdAt : '',
      },
      mdl_detil_riwayat: false,
      mdl_detil_riwayat_add: false,
      mdl_detil_riwayat_add_lampiran: false,
      mdl_detil_riwayat_edit: false,
      mdl_detil_riwayat_hapus: false,
      file_old :'',

      UMUM: UMUM,
      DATA_MASTER: DATA_MASTER,

      btn_add: false,
    };
  },

  methods: {
    getRiwayat: function(){
       fetch(this.$store.state.url.URL_simpeg_anak+'view', {
          method : 'POST',
          headers : {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body : JSON.stringify({
            biodata_id : this.data.biodata_id,
          })
      }).then((res) => res.json()).then((res_data) => {
        this.list_riwayat = res_data;
        console.log(res_data);
      })
    },

    addData : function(number) {
      var formData = new FormData();

      var formData = new FormData();
      formData.append('data_anak_id', this.data.data_anak_id);
      formData.append('nama', this.data.nama);
      formData.append('no_akta', this.data.no_akta);
      formData.append('tgl_lahir', this.data.tgl_lahir);
      formData.append('jns_kelamin_id', this.data.jns_kelamin_id);
      formData.append('strata_ijazah_id', this.data.strata_ijazah_id);
      formData.append('pekerjaan', this.data.pekerjaan);
      formData.append('jns_status_anak_id', this.data.jns_status_anak_id);
      formData.append('biodata_id', this.data.biodata_id);
      formData.append('file', this.data.file);

      fetch(this.$store.state.url.URL_simpeg_anak+'createData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then(res => res.json()).then((data_urusan) => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getRiwayat();
      });
     
    },


    editData : function(){

       var formData = new FormData();
      formData.append('data_anak_id', this.data.data_anak_id);
      formData.append('nama', this.data.nama);
      formData.append('no_akta', this.data.no_akta);
      formData.append('tgl_lahir', this.data.tgl_lahir);
      formData.append('jns_kelamin_id', this.data.jns_kelamin_id);
      formData.append('strata_ijazah_id', this.data.strata_ijazah_id);
      formData.append('pekerjaan', this.data.pekerjaan);
      formData.append('jns_status_anak_id', this.data.jns_status_anak_id);
      formData.append('biodata_id', this.data.biodata_id);
      formData.append('file', this.data.file);
      formData.append('file_old', this.file_old);

      fetch(this.$store.state.url.URL_simpeg_anak+'updateData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then((res_data) => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getRiwayat();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_simpeg_anak + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.data.data_anak_id, file : this.file_old})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getRiwayat();
      });

    },

    selectRiwayat: function(data){

          this.data.data_anak_id = data.data_anak_id;
          this.data.nama = data.nama;
          this.data.no_akta = data.no_akta;
          this.data.tgl_lahir = data.tgl_lahir;
          this.data.jns_kelamin_id = data.jns_kelamin_id;
          this.data.strata_ijazah_id = data.strata_ijazah_id;
          this.data.pekerjaan = data.pekerjaan;
          this.data.jns_status_anak_id = data.jns_status_anak_id;
          this.data.biodata_id = data.biodata_id;
          this.data.createdAt = data.createdAt;

          this.file_old = data.file;
    },

    alertku: function(message, positive, icon) {
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position: "top",
          timeout: 500
        });
    },
    Notify: function(message, positive, icon) {
      this.$q.notify({
        message: message,
        color: positive,
        icon: icon,
        position: "top",
        timeout: 500
      });
    },

  },


  mounted () {
    this.getRiwayat()
    DATA_MASTER.getJK();
  },



};
