const menu = [
    {
        route: '/',
        name: 'Home',
        icon: 'dashboard',
        color: 'light-blue-10',
        icon_color: 'white',
        child: []
    },
    {
        route: '/profile',
        name: 'Profilku',
        icon: 'account_circle',
        color: 'primary',
        icon_color: 'white',
        child: []
    },

    {
        route: '/periode',
        name: 'Periode Kerja',
        icon: 'date_range',
        color: 'primary',
        icon_color: 'white',
        child: []
    },

    {
        route: '/',
        name: 'SKP - JPT',
        icon: 'assignment',
        color: 'light-blue',
        icon_color: 'white',
        child: [
            {
                route: '/pk_unit_sasaran',
                name: 'Perjanjian Kinerja Unit',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
            {
                route: '/manual_ik_unit',
                name: 'Manual IK-JPT',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
            {
                route: '/rencana_aksi_strategis',
                name: 'Rencana Aksi Strategis',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },

        ]
    },

    {
        route: '/',
        name: 'SKP - UMUM',
        icon: 'assignment',
        color: 'light-blue',
        icon_color: 'white',
        child: [
            {
                route: '/pk_unit_sasaran_umum',
                name: 'Rencana Kinerja ASN',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
            {
                route: '/rencana_aksi_strategis_umum',
                name: 'Rencana Aksi',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
           
        ]
    },

    {
        route: '/',
        name: 'Pembagian Peran / Hasil',
        icon: 'hub',
        color: 'light-blue',
        icon_color: 'white',
        child: [
            {
                route: '/mph_jpt',
                name: 'MPH',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
            // {
            //     route: '/mph_umum',
            //     name: 'MPH Non JPT',
            //     icon: 'home',
            //     color: 'primary',
            //     icon_color: 'white',
            //     child: []
            // },
        ]
    },



    {
        route: '/',
        name: 'Worksheet',
        icon: 'format_list_numbered',
        color: 'orange-8',
        icon_color: 'white',
        child: [
            {
                route: '/worksheet_harian_v2',
                name: 'Worksheet',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
            {
                route: '/worksheetVerifikasi',
                name: 'Verifikasi Worksheet',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },

        ]
    },
   


    {
        route: '/',
        name: 'Pelaporan',
        icon: 'receipt',
        color: 'light-blue',
        icon_color: 'white',
        child: [
            {
                route: '/lap_harian',
                name: 'Laporan Harian',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
            {
                route: '/lap_tpp_personal',
                name: 'Laporan TPP Personal',
                icon: 'home',
                color: 'primary',
                icon_color: 'white',
                child: []
            },
            // {
            //     route: '/lap_skp_jpt',
            //     name: 'Form SKP-JPT',
            //     icon: 'home',
            //     color: 'primary',
            //     icon_color: 'white',
            //     child: []
            // },

        ]
    },

    

    // {
    //     route: '/',
    //     name: 'Data Master',
    //     icon: 'settings',
    //     color: 'deep-orange',
    //     icon_color: 'white',
    //     child: [
    //         {
    //             route: '/master_jenis_indikator',
    //             name: 'Master Jenis Indikator',
    //             icon: 'home',
    //             color: 'primary',
    //             icon_color: 'white',
    //             child: []
    //         },
    //     ]
    // },

];



module.exports = {
    menu: menu,

}