//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//





import UMUM from "../../library/umum";
import DATA_MASTER from "../../library/dataMaster";


export default {
  props: ["biodata_id"],
  data() {
    
    return {
      // xbiodata_id : this.biodata_id,
      list_riwayat: [],
      data: {
          riwayat_pengangkatan_id : '',
          uraian : '',
          jns_golongan_id : '',
          tmt_gol : '',
          penanda_tangan_sk : '',
          no_sk : '',
          tgl_sk : '',
          biodata_id : this.biodata_id,
          file : null,
          userId : 0,
          createdAt : '',

      },
      mdl_detil_riwayat: false,
      mdl_detil_riwayat_add: false,
      mdl_detil_riwayat_add_lampiran: false,
      mdl_detil_riwayat_edit: false,
      mdl_detil_riwayat_hapus: false,
      file_old :'',

      UMUM: UMUM,
      DATA_MASTER: DATA_MASTER,
    };
  },

  methods: {
    getRiwayat: function(){
        fetch(this.$store.state.url.URL_simpeg_riwayat_pengangkatan+'view', {
            method : 'POST',
            headers : {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body : JSON.stringify({
              biodata_id : this.biodata_id,
            })
        }).then((res) => res.json()).then((res_data) => {
          this.list_riwayat = res_data;
          // console.log(res_data);
        })
    },
    addData : function(number) {
      var formData = new FormData();
      formData.append('riwayat_pengangkatan_id', this.data.riwayat_pengangkatan_id);
      formData.append('uraian', this.data.uraian);
      formData.append('jns_golongan_id', this.data.jns_golongan_id);
      formData.append('tmt_gol', this.data.tmt_gol);
      formData.append('penanda_tangan_sk', this.data.penanda_tangan_sk);
      formData.append('no_sk', this.data.no_sk);
      formData.append('tgl_sk', this.data.tgl_sk);
      formData.append('biodata_id', this.data.biodata_id);
      formData.append('file', this.data.file);

      fetch(this.$store.state.url.URL_simpeg_riwayat_pengangkatan+'createData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then(res => res.json()).then((data_urusan) => {
          this.alertku("Berhasil Menambah Data!", "blue");
          this.getRiwayat();
      });

    },


    editData : function(){
      var formData = new FormData();
      formData.append('riwayat_pengangkatan_id', this.data.riwayat_pengangkatan_id);
      formData.append('uraian', this.data.uraian);
      formData.append('jns_golongan_id', this.data.jns_golongan_id);
      formData.append('tmt_gol', this.data.tmt_gol);
      formData.append('penanda_tangan_sk', this.data.penanda_tangan_sk);
      formData.append('no_sk', this.data.no_sk);
      formData.append('tgl_sk', this.data.tgl_sk);
      formData.append('biodata_id', this.data.biodata_id);
      formData.append('file', this.data.file);
      formData.append('file_old', this.file_old);


      fetch(this.$store.state.url.URL_simpeg_riwayat_pengangkatan+'updateData', {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then((res_data) => {
          this.alertku("Berhasil Mengubah Data!", "amber");
          this.getRiwayat();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_simpeg_riwayat_pengangkatan + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.data.riwayat_pengangkatan_id, file : this.file_old})
      }).then(res_data => {
          this.alertku("Berhasil Menghapus Data!", "red");
          this.getRiwayat();
      });

    },


    selectRiwayat: function(data){

      this.data.riwayat_pengangkatan_id = data.riwayat_pengangkatan_id;
      this.data.uraian = data.uraian;
      this.data.jns_golongan_id = data.jns_golongan_id;
      this.data.tmt_gol = data.tmt_gol;
      this.data.penanda_tangan_sk = data.penanda_tangan_sk;
      this.data.no_sk = data.no_sk;
      this.data.tgl_sk = data.tgl_sk;
      this.data.biodata_id = data.biodata_id;
      this.data.userId = data.userId;
      this.data.createdAt = data.createdAt;

      this.file_old = data.file;
    },

    alertku: function(message, positive, icon) {
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position: "top",
          timeout: 500
        });
      }

  },


  mounted () {
    this.getRiwayat()
    DATA_MASTER.getGolongan();
  },



};
